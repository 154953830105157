<template>
  <div class>
    <div class="d-flex mb-2">
      <span class="page-header-title">Promotions List</span>
    </div>
    <CCard>
      <CCardBody>
        <div class="d-md-flex d-sm-flex justify-content-end mt-3">
          <div>
            <b-input-group class="mb-2">
              <template #append>
                <b-input-group-text @click="onSearch(searchText)">
                  <CIcon name="cil-search" class="cicon cursor" />
                </b-input-group-text>
              </template>
              <b-form-input
                v-model="searchText"
                placeholder="Title"
                v-on:keyup.enter="onSearch(searchText)"
              ></b-form-input>
            </b-input-group>
          </div>
          <div class="mr-0">
            <b-button
              variant="primary"
              class="float-right ml-3 mt-3 mt-lg-0 mt-md-0 mt-sm-0"
              @click="showAddModal()"
            >
              <em class="fa fa-plus" aria-hidden="true"></em>
              Add New
            </b-button>
          </div>
        </div>

        <div class="table-responsive mt-2">
          <CustomTableUser
            class="vertical-align-middle table-responsive account_table"
            :items="promotionList"
            hover
            striped
            border
            :small="'small'"
            fixed
            caption
            :fields="fields"
            :per-page="perPage"
            :total="total"
            :loadTableData="loadTableData"
            :rowSelectHandler="rowClickHandler"
            :onRowDeleteHandler="deleteTableData"
            :onRowEditHandler="editTableData"
            :rowFliterHandler="rowFliterHandler"
            :currentPagination="currentPageOfPromotions"
            :onClickImage="showImage"
          />
        </div>
      </CCardBody>
    </CCard>
    <!-- Add New Category Modal -->
    <b-modal id="add-promotion-modal" title="Add New Promotion" size="lg">
      <b-form @submit.prevent="addPromotion">
        <label class="asterisk font-weight-bold">Title </label>
        <p class="notification-input">
          <CInput
            placeholder="Title"
            v-model="newPromotion.title"
            required
            was-validated
          />
          <show-errors
            class=""
            :control="$v.newPromotion.title"
            :field="$t('app.template.management.tempalte.name')"
          />
        </p>
        <label class="mt-2 asterisk font-weight-bold">Description </label>
        <p class="">
          <quill-editor
            v-model="newPromotion.description"
            ref="notificationQuillEditor"
            :options="editorOption"
            @change="oneEditorChange($event)"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
          >
          </quill-editor>
          <!-- <CTextarea aria-rowcount="3"  required
            was-validated v-model="newPromotion.description"></CTextarea> -->
          <show-errors
            class=""
            :control="$v.newPromotion.description"
            :field="$t('app.template.management.tempalte.name')"
          />
        </p>
        <label class="mt-2 font-weight-bold asterisk">Promotion Image </label>
        <div>
          <label
            class="btn btn-default btn-primary mt-lg-0 mt-md-0 mt-sm-0 mb-0"
          >
            <em class="fa fa-upload" aria-hidden="true"></em> Upload
            <input
              id="fileupload"
              ref="imageUploader"
              type="file"
              hidden
              @change="onFileChange($event, true)"
              accept="image/png, image/jpeg"
            />
          </label>
          <div v-if="imageFileName" class="ml-2 mb-2">
            {{ imageFileName }}
            <em
              class="fa fa-times-circle cursor mt-1 ml-3"
              @click="deleteFile(0, true)"
              aria-hidden="true"
              style="color: red"
            />
          </div>
        </div>
        <div>
          <span class="mandatory-color error-font">{{
            imageFileErrorMessage
          }}</span>
        </div>
        <label class="mt-4 font-weight-bold">Promotion Media </label>
        <div>
          <label
            class="btn btn-default btn-primary mt-lg-0 mt-md-0 mt-sm-0 mb-0"
          >
            <em class="fa fa-upload" aria-hidden="true"></em> Upload
            <input
              id="fileupload"
              ref="fileUploader"
              type="file"
              hidden
              @change="onFileChange($event, false)"
              accept="video/mp4,application/pdf"
            />
          </label>
          <div v-if="fileName" class="ml-2 mb-2">
            {{ fileName }}
            <em
              class="fa fa-times-circle cursor mt-1 ml-3"
              @click="deleteFile(0, false)"
              aria-hidden="true"
              style="color: red"
            />
          </div>
        </div>
        <div>
          <span class="mandatory-color error-font">{{ fileErrorMessage }}</span>
        </div>
      </b-form>
      <template v-slot:modal-footer="{}">
        <div class="col-lg-12 col-sm-12 col-12 p-0 row ml-0 mr-0">
          <div class="col-lg-12 col-12 col-sm-12 p-0 text-right">
            <b-button
              size="md"
              variant="secondary"
              class="ml-2 px-2"
              @click="$bvModal.hide('add-promotion-modal')"
              >Cancel
            </b-button>
            <b-button
              size="md"
              variant="primary"
              class="ml-2 px-2"
              @click="addPromotion()"
              >Create
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

    <!-- Edit Category Modal -->
    <b-modal id="edit-promotion-modal" title="Promotion Edit" size="lg">
      <b-form @submit.prevent="editPromotion">
        <label class="asterisk font-weight-bold">Title </label>
        <p class="notification-input">
          <CInput
            placeholder="title"
            v-model="selectedPromotion.title"
            required
            was-validated
          />
          <show-errors
            class=""
            :control="$v.selectedPromotion.title"
            :field="$t('app.template.management.tempalte.name')"
          />
        </p>
        <label class="mt-2 asterisk font-weight-bold">Description </label>
        <p class="">
          <quill-editor
            v-model="selectedPromotion.description"
            ref="notificationQuillEditor"
            :options="editorOption"
            @change="oneEditorChange($event)"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
          >
          </quill-editor>
          <!-- <CTextarea aria-rowcount="3"  required
            was-validated v-model="selectedPromotion.description"></CTextarea> -->
          <show-errors
            class=""
            :control="$v.selectedPromotion.description"
            :field="$t('app.template.management.tempalte.name')"
          />
        </p>
        <label class="mt-2 font-weight-bold asterisk">Promotion Image </label>
        <div>
          <label
            class="btn btn-default btn-primary mt-lg-0 mt-md-0 mt-sm-0 mb-0"
          >
            <em class="fa fa-upload" aria-hidden="true"></em> Upload
            <input
              id="fileupload"
              ref="imageUploader"
              type="file"
              hidden
              @change="onFileChange($event, true)"
              accept="image/png, image/jpeg"
            />
          </label>
          <div v-if="imageFileName" class="ml-2 mb-2">
            {{ imageFileName }}
            <em
              class="fa fa-times-circle cursor mt-1 ml-3"
              @click="deleteFile(0, true)"
              aria-hidden="true"
              style="color: red"
            />
          </div>
        </div>
        <div>
          <span class="mandatory-color error-font">{{
            imageFileErrorMessage
          }}</span>
        </div>
        <label class="mt-4 font-weight-bold">Promotion Media </label>
        <div>
          <label
            class="btn btn-default btn-primary mt-lg-0 mt-md-0 mt-sm-0 mb-0"
          >
            <em class="fa fa-upload" aria-hidden="true"></em> Upload
            <input
              id="fileuploader"
              ref="fileUploader"
              type="file"
              hidden
              @change="onFileChange($event, false)"
              accept="video/mp4,application/pdf"
            />
          </label>
          <div v-if="fileName" class="ml-2 mb-2">
            {{ fileName }}
            <em
              class="fa fa-times-circle cursor mt-1 ml-3"
              @click="deleteFile(0, false)"
              aria-hidden="true"
              style="color: red"
            />
          </div>
        </div>
        <div>
          <span class="mandatory-color error-font">{{ fileErrorMessage }}</span>
        </div>
      </b-form>
      <template v-slot:modal-footer="{}">
        <div class="col-lg-12 col-sm-12 col-12 p-0 row ml-0 mr-0">
          <div class="col-lg-12 col-12 col-sm-12 p-0 text-right">
            <b-button
              size="md"
              variant="secondary"
              class="ml-2 px-2"
              @click="onCancelEdit()"
              >Cancel
            </b-button>
            <b-button
              size="md"
              variant="primary"
              class="ml-2 px-2"
              @click="editPromotion()"
              >Save
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

    <!-- Category Details Modal -->
    <b-modal
      id="details-category-modal"
      title="Promotion Details"
      size="lg"
      hide-footer
    >
      <div class="row">
        <div class="col-lg-3 mt-2 pt-1 font-weight-bold">Title</div>
        <div class="col-lg-9 mt-2 pt-1">
          {{ selectedPromotion.title }}
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 mt-2 pt-1 font-weight-bold">Promotion Image</div>
        <div class="col-lg-9 mt-2 pt-1">
          <span v-if="selectedPromotion.image_url">
          <img :src=selectedPromotion.image_url width="80" height="80px" class="mt-1" />
        </span>
        <span v-else> - </span>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 mt-2 pt-1 font-weight-bold">Promotion Media</div>
        <div class="col-lg-9 mt-2 pt-1">
          <span v-if="selectedPromotion.file_url">
            <a :href="selectedPromotion.file_url" target="_blank">
           {{ getFilename(selectedPromotion.file_url) }}
      </a>
        </span>
        <span v-else> - </span>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 mt-2 pt-1 font-weight-bold">Description</div>
        <div class="col-lg-9 mt-2 pt-1">
          <div
            class="ql-snow"
            oncontextmenu="return false;"
            onmousedown="event.preventDefault ? event.preventDefault() : event.returnValue = false"
          >
            <div
              class="content-manage ql-editor"
              draggable="false"
              v-html="selectedPromotion.description"
            ></div>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Confirm Delete Modal -->
    <b-modal
      id="confirm-delete-category"
      hide-header
      class="cancelbtn"
      title="Delete Data"
      ok-title="Yes"
      cancel-title="Cancel"
      @ok="deleteUsers"
    >
      <p class="my-4 wrap">Are you sure you want to delete this promotion?</p>
    </b-modal>
    <b-modal id="image-modal" hide-footer title="Image Preview">
      <template v-slot:default>
        <div class="text-center">
          <img :src="selectedImageUrl" alt="Preview" class="img-fluid" />
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { multipleErrorMessages, senitize } from "@/shared/utils";
import _ from "lodash";
import ShowErrors from "@/components/ShowErrors/ShowErrors.vue";
import { required, maxLength } from "vuelidate/lib/validators";
import CustomTableUser from "@/components/Table/TableUser.vue";
import {
  PROMOTION_CREATE,
  PROMOTION_DELETE,
  PROMOTION_DETAILS,
  PROMOTION_LIST,
  PROMOTION_UPDATE,
} from "../../store/actions.type";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "quill-paste-smart";
import { quillEditor, Quill } from "vue-quill-editor";
import ImageCompress from "quill-image-compress";
Quill.register("modules/imageCompress", ImageCompress);
var Block = Quill.import("blots/block");
var sizeof = require("object-sizeof");
Block.tagName = "div";
Quill.register(Block);
export default {
  name: "promotionList",
  components: { CustomTableUser, ShowErrors, quillEditor },
  data() {
    return {
      editorOption: {
        modules: {
          clipboard: {
            allowed: {
              tags: [
                "a",
                "b",
                "strong",
                "u",
                "s",
                "i",
                "p",
                "br",
                "ul",
                "ol",
                "li",
                "span",
                "h1",
                "h2",
                "div",
                "h3",
                "h4",
                "h5",
              ],
              attributes: ["href", "rel", "target", "class", "style"],
            },
            keepSelection: true,
            substituteBlockElements: true,
            magicPasteLinks: true,
            hooks: {
              uponSanitizeElement(node) {
                console.log(node);
              },
            },
          },
          imageCompress: {
            quality: 0.7, // default
            maxWidth: 1000, // default
            maxHeight: 1000, // default
            imageType: "image/jpeg", // default
            debug: true, // default
            suppressErrorLogging: false, // default
          },

          toolbar: [
            // ["link", "emoji", "image"],
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            // [{ header: 1 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme

            [{ align: [] }],
            ["image", "video", "link"],
            ["clean"],
          ],
        },
      },
      promotionList: [],
      newPromotion: { title: "", description: "" },
      fileName: "",
      fileErrorMessage: "",
      files: [],
      imageFileName: "",
      imageFileErrorMessage: "",
      imageFiles: [],
      searchText: "",
      selectedPromotion: { title: "", description: "",image_url:"",file_url: "", },
      deletePromotion: "",
      selectedImageUrl: "",
      promotionFilter: {
        filters: {
          start: 0,
          limit: 50,
          sort_by: "id",
          order_by: "asc",
        },
        start_date: "",
        end_date: "",
        search: "",
      },
      perPage: 50,
      total: 0,
      currentPage: 1,
      pageStart: 0,
      sortField: "created_at",
      sortDirection: "desc",
      currentPageOfPromotions: 1,

      fields: [
        {
          key: "title",
          label: "Title",
          _classes: "title-name",
          sortable: true,
        },
        {
          key: "image_url",
          label: "Promotion Image",
          _classes: "tbl-width-50",
          sorter: false,
        },
        {
          key: "file_url",
          label: "Promotion Media",
          sortable: true,
          sorter: false,
        },
        {
          key: "productAction",
          label: "Action",
          sortable: false,
          _classes: "",
        },
      ],
    };
  },

  validations: {
    newPromotion: {
      title: { required, maxLength: maxLength(100), senitize },
      description: { required },
    },
    selectedPromotion: {
      title: { required, maxLength: maxLength(100), senitize },
      description: { required },
    },
  },

  async created() {
    this.fetchPromotionList();
  },

  methods: {
   
    getFilename(fileUrl) {
      return fileUrl.split('/').pop();
    },

    oneEditorChange(e) {
      if (e.html == "<div><br></div>") {
        this.newPromotion.description = "";
      } else {
        this.newPromotion.description = e.html;
      }
    },

    /**
     * Resets the selected promotion and file error message, and hides the "edit-promotion-modal" modal.
     *
     * @return {void}
     */
    onCancelEdit() {
      this.selectedPromotion = { title: "", description: "",image_url:"",file_url:"", };
      this.fileErrorMessage = "";
      this.$bvModal.hide("edit-promotion-modal");
    },

    /**
     * @description function to check and validate browsed files
     * @param {file} data
     */
    onFileChange(event, isImage) {
      let totalFileSizeMB = 0;
      this.fileErrorMessage = ""; // Reset generic file error message
      this.imageFileErrorMessage = ""; // Reset image file error message

      const files = event.target.files;
      if (!files) return; // Ensure files are defined

      const validImageTypes = ["image/png", "image/jpeg"];
      const validFileTypes = ["video/mp4", "application/pdf"];

      for (const file of files) {
        const fileSizeMB = file.size / (1024 * 1024); // Convert size to MB
        totalFileSizeMB += fileSizeMB;

        const fileExtension = file.type;
        const isValidImage = validImageTypes.includes(fileExtension);
        const isValidFile = validFileTypes.includes(fileExtension);

        if (isImage) {
          if (!isValidImage) {
            this.imageFileErrorMessage =
              "Please upload a valid image file (png, jpeg)";
            this.$refs.imageUploader.value = ""; // Reset the file input
            return; // Exit the function if an invalid image type is detected
          }
        } else {
          if (!isValidFile) {
            this.fileErrorMessage =
              "Please upload a correct mp4 video or PDF file";
            this.$refs.fileUploader.value = ""; // Reset the file input
            return; // Exit the function if an invalid file type is detected
          }
        }

        if (totalFileSizeMB > 5) {
          if (isImage) {
            this.imageFileErrorMessage = "Please upload less than 5 MB";
          } else {
            this.fileErrorMessage = "Please upload less than 5 MB";
          }
          this.$refs.imageUploader.value = ""; // Reset the file input
          this.$refs.fileUploader.value = ""; // Reset the file input
          return; // Exit the function if the total file size exceeds 5 MB
        }
      }

      // If no errors, add files to the array and set the filename
      if (!this.fileErrorMessage && !this.imageFileErrorMessage) {
        if (isImage) {
          this.imageFiles = Array.from(files);
          this.imageFileName = files[0].name;
        } else {
          this.files = Array.from(files);
          this.fileName = files[0].name;
        }
      }

      this.$refs.imageUploader.value = ""; // Reset the file input
      this.$refs.fileUploader.value = ""; // Reset the file input
    },

    /**
     * Deletes a file from the UI based on the given index and type.
     *
     * @param {number} index - The index of the file to be deleted.
     * @param {boolean} isImage - Indicates if the file is an image.
     * @return {void} This function does not return anything.
     */
    deleteFile(index, isImage) {
      if (isImage) {
        this.imageFileErrorMessage = ""; // Reset image file error message
        if (this.imageFiles.length > 0) {
          this.imageFiles.splice(index, 1);
        }
        this.imageFileName =
          this.imageFiles.length > 0 ? this.imageFiles[0].name : "";
        this.$refs.imageUploader.value = ""; // Reset the file input
      } else {
        this.fileErrorMessage = ""; // Reset generic file error message
        if (this.files.length > 0) {
          this.files.splice(index, 1);
        }
        this.fileName = this.files.length > 0 ? this.files[0].name : "";
        this.$refs.fileUploader.value = ""; // Reset the file input
      }
    },

    /**
     * Displays the image modal with the selected image URL.
     *
     * @param {Object} item - The item object containing the image URL.
     * @return {void}
     */
    showImage(item) {
      this.selectedImageUrl = item.image_url;
      this.$bvModal.show("image-modal");
    },

    /**
     * Hides the image modal.
     *
     * @return {void}
     */
    hideModal() {
      this.$bvModal.hide("image-modal");
    },

    /**
     * Updates the search text in the promotion filter and fetches the promotion list.
     *
     * @param {string} text - The search text to be updated.
     * @return {void} This function does not return anything.
     */
    onSearch(text) {
      this.promotionFilter.search = text;
      this.fetchPromotionList();
    },

    /**
     * Updates the selected promotion category and fetches the promotion list.
     *
     * @param {Object} category - The selected promotion category.
     * @return {Promise<void>} - A promise that resolves when the promotion list is fetched.
     */
    filterCategories(category) {
      this.selectedPromotion = category;
      this.fetchPromotionList();
    },

    /**
     * Fetches the promotion list based on the current page, per page limit, sort field, and sort direction.
     * Updates the promotion list and total count if the API call is successful.
     * Shows a loading indicator while fetching the data.
     * Displays an error message if the API call fails.
     *
     * @return {Promise<void>} A promise that resolves when the promotion list is fetched.
     */
    async fetchPromotionList() {
      this.promotionFilter.filters.start =
        this.currentPage == 1 ? 0 : (this.currentPage - 1) * this.perPage;
      this.promotionFilter.filters.limit = this.perPage;
      this.promotionFilter.filters.sort_by = this.sortField;
      this.promotionFilter.filters.order_by = this.sortDirection;
      this.isLoadingFetchPromotion = this.$loading.show();
      let data = await this.$store.dispatch(
        PROMOTION_LIST,
        this.promotionFilter
      );
      this.isLoadingFetchPromotion.hide();
      if (_.get(data, "success", true)) {
        this.promotionList = _.get(data, "list", []);
        this.total = _.get(data, "total", 0);
      } else {
        let message = _.get(data, "message", "");
        this.promotionList = [];
        this.total = 0;
        if (message.length > 0) {
          this.$toast.error({
            message: message,
          });
        } else {
          this.$toast.removeAll();
          let messageObj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageObj);
        }
      }
    },

    /**
     * @method loadTableData
     * @description Method to load table data
     */
    async loadTableData(currentPage, limit) {
      this.currentPage = currentPage;
      this.pageStart =
        this.currentPage == 1 ? 0 : (this.currentPage - 1) * this.perPage;
      this.perPage = limit;
      this.currentPageOfPromotions = currentPage;
      this.fetchPromotionList();
    },

    /**
     * Asynchronously handles a row click event. If the item has an id, it dispatches a PROMOTION_DETAILS action to the Vuex store,
     * retrieves the title and description of the selected promotion, updates the selectedPromotion object, hides the loading indicator,
     * and shows the details modal.
     *
     * @param {Object} item - The item clicked on
     * @return {Promise<void>} A promise that resolves when the row click event has been handled
     */
    async rowClickHandler(item) {
      if (item.id) {
        this.isLoadingRow = this.$loading.show();
        let data = await this.$store.dispatch(PROMOTION_DETAILS, {
          id: item.id,
        });
        this.selectedPromotion.title = data.title;
        this.selectedPromotion.description = data.description;
        this.selectedPromotion.image_url = data.image_url;
        this.selectedPromotion.file_url = data.file_url;
        this.isLoadingRow.hide();
        this.showDetailsModal();
      }
      // Handle row click event
    },

    /**
     * Deletes table data by confirming with the user first.
     *
     * @param {Object} item - The item to be deleted from the table.
     * @return {void} This function does not return anything.
     */
    deleteTableData(item) {
      this.confirmDelete(item);
    },

    /**
     * @method rowFliterHandler
     * @description Method to load table data with filter
     */
    async rowFliterHandler(e) {
      this.sortDirection = e.asc ? "asc" : "desc";
      this.sortField = e.column;
      this.fetchPromotionList();
    },

    /**
     * @method editTableData
     * @description redirects edit page
     */
    editTableData(item) {
      this.showEditModal(item);
    },

    /**
     * Resets the form fields and shows the modal for adding a new promotion.
     *
     * @return {void}
     */
    showAddModal() {
      this.$v.newPromotion.$reset();
      this.newPromotion = { title: "", description: "" };
      this.files = [];
      this.imageFiles = [];
      this.fileErrorMessage = "";
      (this.imageFileErrorMessage = ""), (this.fileName = "");
      this.imageFileName = "";
      this.$bvModal.show("add-promotion-modal");
    },

    /**
     * Adds a new promotion.
     *
     * @return {Promise<void>} A Promise that resolves when the promotion is added successfully.
     */
    async addPromotion() {
      this.$v.$touch();
      if (this.imageFiles.length <= 0) {
        this.imageFileErrorMessage = "Please upload file.";
        return;
      }
      if (sizeof(this.newPromotion.description) > 1048576 * 5) {
        this.$toast.error({
          message: "Maximum size is exceeded.",
        });
        return;
      }
      if (!this.$v.newPromotion.$invalid) {
        let formData = new FormData();
        formData.append("title", this.newPromotion.title);
        formData.append("description", this.newPromotion.description);
        this.files[0] && formData.append("media", this.files[0]);
        this.imageFiles[0] && formData.append("image", this.imageFiles[0]);
        this.fileErrorMessage = "";
        this.isLoadingSubmitNotify = this.$loading.show();
        await this.$store.dispatch(PROMOTION_CREATE, formData).then((data) => {
          if (_.get(data, "data.success", false)) {
            this.files = [];
            this.imageFiles = [];
            this.fileErrorMessage = "";
            (this.imageFileErrorMessage = ""), (this.fileName = "");
            this.imageFileName = "";
            this.isLoadingSubmitNotify.hide();
            this.$toast.removeAll();
            this.$toast.success({
              message: _.get(data, "data.message", ""),
            });
            this.$bvModal.hide("add-promotion-modal");
            this.$v.newPromotion.$reset();
            this.fetchPromotionList();
          } else {
            this.files = [];
            this.imageFiles = [];
            this.fileErrorMessage = "";
            (this.imageFileErrorMessage = ""), (this.fileName = "");
            this.imageFileName = "";
            this.$v.newPromotion.$reset();
            this.$bvModal.hide("add-promotion-modal");
            this.isLoadingSubmitNotify.hide();
            let message = _.get(data, "message", "");
            if (message.length > 0) {
              this.$toast.error({
                message: message,
              });
            } else {
              this.$toast.removeAll();
              let messageObj = _.get(data, "message", "");
              multipleErrorMessages(this.$toast, messageObj);
            }
          }
        });
      }
    },

    /**
     * Update the selected promotion with the details of the provided item.
     *
     * @param {Object} item - The item containing the promotion details to be edited.
     * @return {void}
     */
    showEditModal(item) {
      this.selectedPromotion = { ...item };
      let filePath = item.file_url || "";
      let imagePath = item.image_url || "";

      if (filePath) {
        const fileParts = filePath.split("/");
        this.fileName = fileParts[fileParts.length - 1];
      } else {
        this.fileName = "";
      }

      if (imagePath) {
        const imageParts = imagePath.split("/");
        this.imageFileName = imageParts[imageParts.length - 1];
      } else {
        this.imageFileName = "";
      }

      this.fileErrorMessage = "";
      this.imageFileErrorMessage = "";
      this.$bvModal.show("edit-promotion-modal");
    },

    /**
     * Edit the promotion by updating its details.
     *
     * @return {Promise<void>} A Promise that resolves once the promotion is successfully updated.
     */
    async editPromotion() {
      // Edit category logic here
      this.$v.$touch();
      if (this.imageFiles.length <= 0 && this.imageFileName == "") {
        this.imageFileErrorMessage = "Please upload file.";
        return;
      }
      if (sizeof(this.selectedPromotion.description) > 1048576 * 5) {
        this.$toast.error({
          message: "Maximum size is exceeded.",
        });
        return;
      }
      if (!this.$v.selectedPromotion.$invalid) {
        let formData = new FormData();
        formData.append("id", this.selectedPromotion.id);
        formData.append("title", this.selectedPromotion.title);
        formData.append("description", this.selectedPromotion.description);
        this.files[0] && formData.append("media", this.files[0]);
        this.imageFiles[0] && formData.append("image", this.imageFiles[0]);
        if (
          this.selectedPromotion.file_url != "" &&
          this.selectedPromotion.file_url != null &&
          this.fileName == ""
        ) {
          formData.append("isDelete", 1);
        }
        if (
          this.selectedPromotion.image_url != "" &&
          this.selectedPromotion.image_url != null &&
          this.imageFileName == ""
        ) {
          formData.append("isDeleteImage", 1);
        }
        this.fileErrorMessage = "";
        this.isLoadingSubmitNotify = this.$loading.show();
        await this.$store.dispatch(PROMOTION_UPDATE, formData).then((data) => {
          if (_.get(data, "data.success", false)) {
            this.files = [];
            this.fileName = "";
            this.isLoadingSubmitNotify.hide();
            this.$toast.removeAll();
            this.$toast.success({
              message: _.get(data, "data.message", ""),
            });
            this.$bvModal.hide("edit-promotion-modal");
            this.$v.selectedPromotion.$reset();
            this.fetchPromotionList();
          } else {
            this.files = [];
            this.fileName = "";
            this.$v.selectedPromotion.$reset();
            this.$bvModal.hide("edit-promotion-modal");
            this.isLoadingSubmitNotify.hide();
            let message = _.get(data, "message", "");
            if (message.length > 0) {
              this.$toast.error({
                message: message,
              });
            } else {
              this.$toast.removeAll();
              let messageObj = _.get(data, "message", "");
              multipleErrorMessages(this.$toast, messageObj);
            }
          }
        });
      }
    },

    /**
     * Show the details modal for the category.
     *
     * @return {void} No return value
     */
    showDetailsModal() {
      this.$bvModal.show("details-category-modal");
    },

    /**
     * Confirms the deletion of an item and shows the "confirm-delete-category" modal.
     *
     * @param {Object} item - The item to be deleted.
     * @return {void}
     */
    confirmDelete(item) {
      this.deletePromotion = item;
      this.$bvModal.show("confirm-delete-category");
    },

    /**
     * Deletes a user from the database and updates the UI accordingly.
     *
     * @return {Promise<void>} A promise that resolves when the user is successfully deleted.
     */
    async deleteUsers() {
      if (this.deletePromotion.id) {
        let data = await this.$store.dispatch(PROMOTION_DELETE, {
          id: this.deletePromotion.id,
        });
        if (_.get(data, "success", true)) {
          this.$toast.removeAll();
          this.$toast.success(_.get(data, "data.message", ""));
          if (this.promotionList.length == 1) {
            this.pageStart = 0;
            this.currentPage = 1;
            this.perPage = 20;
          }

          this.fetchPromotionList();
        } else {
          this.$toast.removeAll();
          let message = _.get(data, "data.message", "");
          multipleErrorMessages(this.$toast, message);
        }
      }
      // Delete category logic here
      this.$bvModal.hide("confirm-delete-category");
    },
  },
};
</script>
<style>
.title-name {
  width: 300px !important;
}
.category_description {
  width: 200px;
}
.category_url {
  width: 400px;
  min-width: 200px;
}
.ql-editing {
  left: 1px !important;
}

.ql-container .ql-editor {
  min-height: 225px !important;
}
.content-manage {
  padding: 0px !important;
}

.quill-text {
  min-height: 225px !important;
}

.ql-editor {
  min-height: 225px !important;
}

.wrap {
  word-break: break-word;
}
.img {
  /* For Opera and <= IE9, we need to add unselectable="on" attribute onto each element */
  /* Check this site for more details: http://help.dottoro.com/lhwdpnva.php */
  -moz-user-select: none; /* These user-select properties are inheritable, used to prevent text selection */
  -webkit-user-select: none;
  -ms-user-select: none; /* From IE10 only */
  user-select: none; /* Not valid CSS yet, as of July 2012 */

  -webkit-user-drag: none; /* Prevents dragging of images/divs etc */
}
</style>
